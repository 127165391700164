<template>
    <div :class="!topBarShow ? 'winningList-container active' : 'winningList-container'
        ">
        <van-nav-bar v-if="topBarShow" @click-left="goBack" left-arrow title="抽奖记录" :border="false" />
        <div class="title">
            <div class="cishu">
                <p>当前次数</p>
                <p>{{ winningList.now }}</p>
            </div>
            <div class="allcishu">
                <p>总次数</p>
                <p>{{ winningList.all }}</p>
            </div>
            <div class="shiyong">
                <p>使用次数</p>
                <p>{{ winningList.used }}</p>
            </div>
        </div>
        <div class="tableContent">
            <div class="contentTop">
                <p>时间</p>
                <p>增加/减少</p>
                <p>原因</p>
            </div>
            <div class="liebiao">
                <div class="liebiaoOne" v-for="(item, index) in winningList.list" :key="index">
                    <div class="time">{{ item.created_at }}</div>
                    <div class="zenjia" v-if="item.type == 4">+1</div>
                    <div class="jianshao" v-if="item.type != 4">-1</div>
                    <div class="yuanyin">
                        <span v-if="item.type == 1">抽奖</span>
                        <span v-if="item.type == 2">抽奖</span>
                        <span v-if="item.type == 3">积分抽奖</span>
                        <span v-if="item.type == 4">分享邀请</span>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
    
<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { NavBar, Toast } from "vant";
import { useStore } from "vuex";
import { isWeiChat } from "@/utils/environment";
import Api from "@/API";
export default {
    name: "winningList",
    components: {
        [NavBar.name]: NavBar,
    },
    setup(props) {
        //中奖记录列表
        let winningList = ref([]);
        //使用vuex
        const store = useStore();
        //使用路由
        const router = useRouter();
        //创建API对象
        const API = new Api();
        // 顶部栏显示隐藏
        const topBarShow = !isWeiChat();
        //返回
        function goBack() {
            router.back();
        }
        //初始化函数
        function init() {
            getpitchInLog();
        }
        //获取中奖记录
        const getpitchInLog = async () => {
            let res = await API.pitchInLog({
                token: store.state.Token,
                luck_id: store.state.Luckid,
            });
            winningList.value = res.data;
        };
        onMounted(() => {
            init();
        });
        return {
            winningList,
            goBack,
            topBarShow,
        };
    },
};
</script>
    
<style lang="scss">
:root {
    --van-nav-bar-icon-color: #000;
}

.winningList-container.active {
    .van-nav-bar {
        padding-top: 0;
    }
}

.winningList-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .van-nav-bar {
        padding-top: 50px;
    }

    .title {
        padding: 30px;
        display: flex;
        justify-content: space-between;
        align-content: center;

        div {
            text-align: center;
            font-size: 30px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #333333;

            p {
                margin-bottom: 20px;
            }
        }
    }

    .tableContent {
        padding: 50px 30px 0 30px;

        .contentTop {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                font-size: 30px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: bold;
                color: #333333;
                width: 33.33%;
                text-align: center;
            }
        }

        .liebiao {
            width: 100%;
            height: 1150px;
            margin-top: 26px;
            overflow-y: auto; // 开启纵向滚动条

            .liebiaoOne {
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-align: center;
                margin-bottom: 38px;

                div {
                    width: 33.33%;
                }

                .time {
                    font-size: 26px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #666666;
                }

                .zenjia {
                    font-size: 30px;
                    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                    font-weight: bold;
                    color: #333333;
                }

                .jianshao {
                    font-size: 30px;
                    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                    font-weight: bold;
                    color: #EB4D57;
                }

                .yuanyin {
                    font-size: 26px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                }
            }

        }

    }
}
</style>